<template>
  <section class="story" :class="{ 'is-open': isOpen }" v-touch:swipe="swipeHandler">
    <div class="o-panel">
      <div class="o-panel__inner">
        <div class="story__content">
          <div class="story__wrapper story__wrapper--images" ref="images">
            <transition
              name="cross-fade"
              v-for="(storyItem, index) in storyData"
              :key="index"
            >
              <img
                v-show="currentSlide === index"
                :src="storyItem.img"
                :alt="'image' + index"
                class="story__image"
              />
            </transition>
          </div>
          <div class="story__wrapper story__wrapper--copy" ref="copy">
            <transition name="fade" mode="out-in">
              <div
                v-for="(storyItem, index) in storyData"
                :key="index"
                v-if="currentSlide === index"
                class="story__copy"
              >
                <h1 class="u-h1">{{ storyItem.headline }}</h1>
                <template v-if="isMobile">
                  <SlideUpDown :active="isOpen" :duration="300">
                    <p class="u-p">{{ storyItem.copy }}</p>
                  </SlideUpDown>
                  <button
                    class="story__toggle"
                    :class="{ 'is-open': isOpen }"
                    @click="toggleContent"
                  >
                    <template v-if="!isOpen">
                      <span>Mehr Lesen</span>
                    </template>
                  </button>
                </template>
                <template v-else>
                  <p class="u-p">{{ storyItem.copy }}</p>
                </template>
              </div>
            </transition>
          </div>
          <div class="story__bg" ref="background">
            <h1 class="u-h1" ref="counter">&nbsp;0{{ currentSlide + 1 }}/0{{ storyData.length }}</h1>
            <div class="story__icons">

              <ShareLink class="story__icon story__icon--fb" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsymphonyofbetter.de" :width="626" :height="800">
                <IconFacebookSimple />
              </ShareLink>

              <ShareLink class="story__icon story__icon--tw" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fsymphonyofbetter.de" :width="600" :height="254">
                <IconTwitterSimple />
              </ShareLink>

              <a class="story__icon story__icon--link" href="mailto:?&subject=Aspirin%20Symphony%20of%20Better&body=https%3A%2F%2Fsymphonyofbetter.de" target="_blank">
                <IconLinkSimple />
              </a>

              <span class="story__icon story__icon--close" @click="toggleContent">
                <IconClose />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="story__button story__button--prev">
      <transition name="quick-slide-reverse">
        <div v-show="currentSlide > 0" @click.prevent="prevSlide">
          <div>
            <button><SlideNextButton /></button>
          </div>
        </div>
      </transition>
    </div>

    <div class="story__button story__button--next">
      <transition name="quick-slide">
        <div
          v-show="currentSlide < storyData.length - 1"
          @click.prevent="nextSlide"
        >
          <button><SlideNextButton /></button>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
  import {TweenMax, Power1, CSSPlugin} from 'gsap/all'
  import storyData from '@/content/story'
  import Vue from 'vue'
  import Vue2TouchEvents from 'vue2-touch-events'
  Vue.use(Vue2TouchEvents)
  import SlideUpDown from 'vue-slide-up-down'
  import SlideNextButton from '@/assets/images/new/slide-next.svg?inline'
  import ShareLink from '@/components/ShareLink'

  import IconInstagramSimple from '@/assets/images/icon-instagram-simple.svg?inline'
  import IconFacebookSimple from '@/assets/images/icon-facebook-simple.svg?inline'
  import IconLinkSimple from '@/assets/images/icon-link-simple.svg?inline'
  import IconYouTubeSimple from '@/assets/images/icon-yt.svg?inline'
  import IconTwitterSimple from '@/assets/images/icon-twitter.svg?inline'
  import IconClose from '@/assets/images/icon-x.svg?inline'

  const plugins = [TweenMax, Power1, CSSPlugin] // eslint-disable-line no-unused-vars

  export default {
    components: {
      SlideNextButton,
      ShareLink,
      IconFacebookSimple,
      IconLinkSimple,
      IconTwitterSimple,
      IconClose,
      SlideUpDown
    },
    data () {
      return {
        timeLine: null,
        animationDuration: 0.3,
        currentSlide: 0,
        layoutBreakpoint: '700px',
        toggleContentBreakpoint: '900px',
        isMobile: false,
        isTablet: false,
        isOpen: false,
        storyData
      }
    },
    mounted () {
      this.isMobile = window.matchMedia('(max-width: ' + this.layoutBreakpoint + ')').matches
      this.isTablet = window.matchMedia('(max-width: ' + this.toggleContentBreakpoint + ')').matches
    },
    methods: {
      toggleContent() {
        this.isOpen = !this.isOpen
      },
      selectSlide(index) {
        this.slideAnimation()
        setTimeout(() => {
          this.currentSlide = index
        }, this.animationDuration * 1000)

      },
      swipeHandler (direction) {
        if (direction === 'left') {
          this.nextSlide()
        } else if (direction === 'right') {
          this.prevSlide()
        }
      },
      prevSlide () {
        this.slideAnimation()
        setTimeout(() => {
          this.currentSlide = Math.max(0, this.currentSlide - 1)
        }, this.animationDuration * 1000)
      },
      nextSlide () {
          this.slideAnimation()
          setTimeout(() => {
            this.currentSlide = Math.min(this.storyData.length - 1, this.currentSlide + 1)
          }, this.animationDuration * 1000)
      },
      slideAnimation () {
        if (this.isMobile) {
          TweenMax.to(this.$refs.images, this.animationDuration, {
            left: '51%',
            top: '2%',
            yoyo:true, repeat:1
          })
          TweenMax.to(this.$refs.copy, this.animationDuration, {
            left: '50%',
            top: '5%',
            delay: 0.05,
            yoyo:true, repeat:1
          })
        } else {
          TweenMax.to(this.$refs.images, this.animationDuration, {
            left: '24%',
            top: '29%',
            yoyo:true, repeat:1
          })
          TweenMax.to(this.$refs.copy, this.animationDuration, {
            top: '49.3%',
            height: '95.3%',
            delay: 0.05,
            yoyo:true, repeat:1
          })
        }
        TweenMax.to(this.$refs.background, this.animationDuration, {
          scale: 0.95,
          yoyo:true, repeat:1
        })
        this.isOpen = false
      },
    },
  }
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/fonts'
@require '../assets/style/1-settings/layout'
@require '../assets/style/2-tools/responsive'
@require '../assets/style/2-tools/mixins'
@require '../assets/style/7-utils/decorators'
@require '../assets/style/7-utils/transitions'

.story
  color $colorBlack
  background-image url('~@/assets/images/bg/bg-story.jpg')
  background-size cover
  background-position 50% 100%
  padding 16rem 0 12rem 0
  position relative
  overflow hidden

  +mobile()
    padding 3rem 0
    padding-bottom 8rem
    overflow visible

  &__content
    position relative
    user-select none
    lost-utility clearfix

    +above('m')
      lost-column 10 / 12
      lost-move 1 / 12

  &__wrapper
    position absolute
    transform translate(-50%, -50%)

    > div
      height 100%
      display flex
      flex-direction column
      justify-content center
      +mobile()
        align-items flex-start

    +mobile()
      top 0
      left 50%
      transform translateX(-50%)

    &--images
      z-index 2
      background white
      border-bottom-right-radius 20%
      box-shadow 0px 10px 26px 0px rgba(0, 0, 0, 0.2)

      +above('m')
        top 25%
        left 21%
        vw-desktop-max-width(
          width 510px,
          height 341px
        )

      +mobile()
        width 80vw
        height auto
        left 54%

        &:before
          content ''
          padding-top 72%
          width 100%
          display block

    &--copy
      z-index 1
      background white
      border-radius 0.5rem
      box-shadow 0 34px 26px 0 rgba(0,0,0,0.40)

      responsive(
        (height 306px) \
        (width 350px),
        (height 506px) \
        (width 650px)
      )
      +tablet()
        height auto
        min-height 400px
        left 72%
      +above('m')
        top 50%
        left 70%

      +mobile()
        top 4%
        left 47%
        width 90%
        min-height 420px
        height auto
        overflow hidden
        text-align left
      @media(max-height 670px)
        min-height 80vh

  &__image
    position absolute
    border-bottom-right-radius 20%
    top 0
    left 0
    width 100%
    height auto

  &__copy
    padding-left 6rem
    padding-right 2rem

    +above('l')
      padding-left 7rem
      padding-right 6rem
    +tablet()
      padding-bottom 2rem
      padding-left 4rem
      padding-right 4rem
      h1
        padding-top 2rem
    +mobile()
      padding 69% 2rem 2rem
      h1
        padding-top 2rem

  &__clickarea
    position absolute
    top -10%
    width 40%
    height 120%
    background red
    z-index 4
    opacity 0

    +mobile()
      display none

    &--prev
      background red
      left 50%
      transform translate(-100%, 0)

    &--next
      background blue
      right 50%
      transform translate(100%, 0)

  &__toggle
    text-transform uppercase
    font-family $fontSemiBold
    padding 0
    background none
    border none

  &__button
    svg
      width 54px
      height 102px
      +mobile()
        width 44px
        height 92px

    cursor pointer
    position absolute
    top 53%
    transform translate(2px, -50%)
    z-index 2

    button
      background none
      border none
      padding 0

    &--prev
      left 0
      transform translate(-2px, -50%)
      transition all 0.3s ease

      svg
        transform scale(-1)

      .is-open &
        left -50px

    &--next
      right 0
      transition all 0.3s ease

      .is-open &
        right -50px

  &__bg
    position relative
    background $colorBrand

    background-image linear-gradient(180deg, #00D3C2 0%, #00A88F 100%)
    box-shadow 0 34px 26px 0 rgba(0,0,0,0.40)

    width 100%
    margin-left 4rem
    border-radius 0.5rem
    responsive(
      height 334px,
      height 434px
    )

    +mobile()
      top 2rem
      margin-left 1.3rem
      width 89%
      min-height 430px
      transition width 0.3s ease

      .is-open &
        width 100%

    @media(max-height 670px)
      min-height 86vh

    h1
      position absolute
      font-size responsive 35px 72px
      font-range $phoneWidth $desktopMaxWidth
      opacity 0.2
      bottom 2rem
      color white
      z-index 1
      margin 0
      responsive(
        left 1px,
        left 50px
      )
      +mobile()
        display none

  &__icons
    position absolute
    right 1.9%
    display flex
    flex-direction column
    align-items center
    justify-content center
    height 100%

    +mobile()
      right 4%
      bottom 2rem
      display none
      height auto

      .is-open &
        display block

  &__icon
    display block
    text-align center

    &:not(:first-child)
      margin-top 30px

    &--fb
      > svg
        width 10px
        height 20px

    &--tw
      > svg
        width 18px
        height 18px

    &--link
      > svg
        width 23px
        height 23px

    &--insta,
    &--youtube
      > svg
        width 18px
        height 18px

    &--close
      width 12px
      height 12px
      display none
      margin-left 5px

      +mobile()
        display block

</style>
