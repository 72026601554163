/* eslint-disable */
const data = [
  {
    img: require('@/assets/images/story/story_04@2x.jpg'),
    headline: 'Unser Gesicht verrät mehr als wir denken',
    copy:
      'Es gibt ca. 7.000 Sprachen auf der Welt. Gut, dass dein Gesicht eine Sprache spricht, die man überall versteht. Denn es verrät genau, wie du dich fühlst: mit 43 Muskeln, die sich je nach Befinden anspannen oder entspannen. So erkennt man z.B. an den Augenpartien, ob dich ein Kopfschmerz unglücklich macht – und wann es dir durch Aspirin® wieder besser geht.'
  },
  {
    img: require('@/assets/images/story/story_03@2x.jpg'),
    headline: 'So fangen wir das Gefühl von „Besser“ ein',
    copy: 'Um Gefühle in Daten zu verwandeln, haben wir per Kamera die Gesichter von Menschen mit Kopfschmerzen beobachtet.  Dabei wurden selbst kleinste Veränderungen ihrer Mimik eingefangen, die das emotionale Befinden bestimmen, während Aspirin® seine Wirkung entfaltet. Eine Software konnte dann die Emotionen in den Gesichtern auslesen: von unglücklich über Entspannung – bis zum Gefühl von „Besser“ nach der Einnahme der Aspirin® Tablette.'
  },
  {
    img: require('@/assets/images/story/story_02@2x.jpg'),
    headline: 'Wie werden aus diesen Gefühlen Klänge?',
    copy:
      'Jeder gemessenen Emotion der Teilnehmer – ganz gleich ob positiv oder negativ - haben wir einen spezifischen Klang zugeordnet. Dadurch entstanden aus den individuellen Gesichtsdaten unverwechselbare Klangbilder. Diese hat unser Sound-Artist gesammelt und daraus die „Symphony of Better“ komponiert – eine musikalische Liebeserklärung an das Gefühl von „Besser“ mit Aspirin®.'
  },
  {
    img: require('@/assets/images/story/story_01@2x.jpg'),
    headline: 'Wie sorgt Aspirin® für das „Besser“ Gefühl?',
    copy:
      'Der Wirkstoff von Aspirin® ist die Acetylsalicylsäure. Sie hemmt die Bildung der Schmerzbotenstoffe, der sogenannten „Prostaglandine“. Dadurch nimmt das Schmerzsignal im Körper ab und wir fühlen uns schnell besser. Durch die nachlassenden Schmerzen entspannen sich unsere Muskeln und unser Gesicht spiegelt ebenfalls „Besser“ wider.'
  }
]

export default data
